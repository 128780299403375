import { Deal } from '~/domain/deal';
import { Image } from '~/shared/types';

export type DealCatalog = {
  _id: string;
  title: string;
  icon: Image;
};

export type DealCatalogWithDeals = DealCatalog & {
  deals: Deal[];
  totalDeals: number;
};

export enum DealCatalogSort {
  DEFAULT = 'default',
  POINT_LOW_TO_HIGH = 'priceAsc',
  POINT_HIGH_TO_LOW = 'priceDesc',
}
