import { QueryStatus } from 'react-query';
import { PaginationResponse, routeTo } from '~/shared';
import { Deal as DealType } from '~/domain/deal';
import { RootNavigationRoute } from '~/features/Navigation';
import { Deal } from '~/features/Deal';
import { Loader } from '@setel/web-ui';
import { EVENT_SOURCES } from '~/shared/analytics.events';
import { Link } from '@remix-run/react';

export type DealsProps = {
  status: QueryStatus;
  pages?: PaginationResponse<DealType>[];
};

export const Deals = ({ status, pages }: DealsProps) => {
  if (status === 'loading') {
    return (
      <div className="flex justify-center h-screen-1/2">
        <Loader type="spinner-blue" />
      </div>
    );
  }
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5 gap-5">
      {pages?.flatMap(({ data }) =>
        data.map((deal) => (
          <Link
            key={deal._id}
            to={{
              pathname: routeTo(RootNavigationRoute.DEAL, {
                dealId: deal._id,
                dealName: encodeURIComponent(
                  deal.voucherBatch.content.title ?? '-'
                ),
              }),
              search: new URLSearchParams({
                source: EVENT_SOURCES.deal_catalogue_page,
              }).toString(),
            }}
          >
            <a>
              <Deal {...deal} />
            </a>
          </Link>
        ))
      )}
    </div>
  );
};
