import { LoadMore } from '~/components/LoadMore';
import { useEffect } from 'react';
import { trackUserEvent, useRouter } from '~/shared';
import { EVENT_TYPES } from '~/shared/analytics.events';
import { Deals } from './Deals';
import { Sort } from './Sort';
import { useFetchCatalogueDetails } from './useFetchCatalogueDetails';

export const CatalogueDetails = () => {
  const {
    catalogueDetails,
    sort,
    setSort,
    pages,
    status,
    fetchNextPage: fetchMore,
    isFetchingNextPage: isFetchingMore,
    hasNextPage: canFetchMore,
  } = useFetchCatalogueDetails();

  const { query } = useRouter();

  useEffect(() => {
    if (!catalogueDetails) {
      return;
    }
    trackUserEvent(EVENT_TYPES.deal_deal_order_view_catalogue, {
      deal_catalogue_name: catalogueDetails.title,
      deal_catalogue_id: catalogueDetails._id,
      source: query.source,
    });
  }, [catalogueDetails?._id]);

  return (
    <div className="container mx-auto py-5 md:py-10">
      <div className="flex flex-col md:flex-row justify-between md:items-center mb-10">
        <p className="text-gray-dark font-medium text-lg md:text-2xl">
          {catalogueDetails?.title}
        </p>
        <Sort sort={sort} onSort={setSort} />
      </div>
      <Deals pages={pages} status={status} />
      <LoadMore
        className="mt-10"
        onFetchMore={fetchMore}
        loading={Boolean(isFetchingMore)}
        show={Boolean(canFetchMore)}
      />
    </div>
  );
};
