import { Deal, getCurrentDealPrice } from '~/domain/deal';
import { PaginationParams, PaginationResponse } from '~/shared';
import { apiClient } from '~/shared/axios';
import {
  DealCatalog,
  DealCatalogSort,
  DealCatalogWithDeals,
} from './catalogue.type';

export const listCataloguesWithDeals = ({
  pageToken,
  perPage,
  perCatalogue,
}: PaginationParams & { perCatalogue?: number }) =>
  apiClient
    .get<PaginationResponse<DealCatalogWithDeals>>('/deals/catalogues/deals', {
      public: true,
      params: {
        pageToken,
        perPage,
        perCatalogue,
      },
    })
    .then(({ data: { data, nextPageToken } }) => ({
      nextPageToken,
      data: data.map((catalogue) => ({
        ...catalogue,
        deals: catalogue.deals.map((deal) => ({
          ...deal,
          price: getCurrentDealPrice(deal.price),
        })),
      })),
    }));

export const listCatalogueDealsById = ({
  pageToken,
  perPage,
  catalogueId,
  sort,
}: PaginationParams & {
  catalogueId?: number | string;
  sort: DealCatalogSort;
}) =>
  apiClient
    .get<PaginationResponse<Deal>>(`/deals/catalogues/${catalogueId}/deals`, {
      public: true,
      params: {
        pageToken,
        perPage,
        ...(sort && { sort }),
      },
    })
    .then(({ data: { data, nextPageToken } }) => ({
      nextPageToken,
      data: data.map((deal) => ({
        ...deal,
        price: getCurrentDealPrice(deal.price),
      })),
    }));

export const getCatalogueById = (catalogueId: string) =>
  apiClient
    .get<DealCatalog>(`/deals/catalogues/${catalogueId}`)
    .then((resp) => resp.data);
