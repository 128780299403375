import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const SLIDE_ITEM_ATTR = 'data-slider-slide-index';
const SLIDER_ATTR = 'data-slider';
const SLIDER_CONTAINER_ATTR = 'data-slider-container';

export interface ScrollSnapSliderOptions {
  slideBaseWidth: number;
  slideSpacing: number;
  // whether to apply container-aware styles, useful if Slider
  // is placed inside a "container" element .eg Tailwind's container
  containerMode?: boolean;
}

export function useScrollSnapSlider(opt: ScrollSnapSliderOptions) {
  const sliderRef = useRef<HTMLDivElement>(null);
  const { slideBaseWidth, slideSpacing, containerMode } = opt;

  const sliderProps = useMemo(
    () => ({
      [SLIDER_ATTR]: '',
      [containerMode ? SLIDER_CONTAINER_ATTR : '']: '',
      ref: sliderRef,
      style: {
        '--slide-base-width': `${slideBaseWidth}px`,
        '--slide-spacing': `${slideSpacing}px`,
      } as any,
    }),
    [slideBaseWidth, slideSpacing, containerMode]
  );

  const getSlideItemProps = useCallback((i: number) => {
    return {
      [SLIDE_ITEM_ATTR]: i,
    };
  }, []);

  const [visibilities, setVisibilities] = useState<boolean[]>([]);
  const canPrev = visibilities[0] === false;
  const canNext = visibilities[visibilities.length - 1] === false;

  const prev = useCallback(() => {
    if (!sliderRef.current) return;
    scrollToSlide(sliderRef.current, visibilities.indexOf(true) - 1);
  }, [visibilities]);

  const next = useCallback(() => {
    if (!sliderRef.current) return;
    scrollToSlide(sliderRef.current, visibilities.lastIndexOf(true) + 1);
  }, [visibilities]);

  useEffect(() => {
    if (!sliderRef.current) return;

    const ob = new IntersectionObserver(
      (entries) => {
        for (const { target, intersectionRatio } of entries) {
          const slideIndex = target.getAttribute(SLIDE_ITEM_ATTR);
          if (slideIndex) {
            setVisibilities((arr) => {
              const _arr = arr.slice();
              _arr[+slideIndex] = intersectionRatio > 0;
              return _arr;
            });
          }
        }
      },
      {
        root: sliderRef.current,
        threshold: [0, 1],
      }
    );
    // observe all children
    for (const slide of sliderRef.current.querySelectorAll(
      `[${SLIDE_ITEM_ATTR}]`
    )) {
      ob.observe(slide);
    }

    return () => {
      ob.disconnect();
    };
  }, [sliderRef.current]);

  return {
    sliderRef,
    slidesVisibilities: visibilities,
    canNext,
    canPrev,
    goToNextSlide: next,
    goToPrevSlide: prev,

    sliderProps,
    getSlideItemProps,
  };
}

function scrollToSlide(slider: Element, slideIndex: number) {
  const nextSlide = slider.querySelector(
    `[${SLIDE_ITEM_ATTR}="${slideIndex}"]`
  );
  nextSlide?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'nearest',
  });
}
