import cn from 'classnames';
import { ChevronDownIcon, IconButton } from '@setel/web-ui';

type ArrowProps = {
  className?: string;
  onClick?(): void;
  next?: boolean;
  size?: number;
};

const classes = [
  'flex items-center justify-center',
  'bg-white',
  'rounded-full',
  'shadow-md',
  'absolute',
  'z-10',
  'border',
  'border-gray-light',
  'border-opacity-10',
];

export const Arrow = ({ onClick, next, size = 50, className }: ArrowProps) => (
  <div
    className={cn(classes, {
      hidden: className?.includes('slick-disabled'),
    })}
    style={{
      width: size,
      height: size,
      top: `calc(50% - ${size / 2}px)`,
      ...(next && { right: -Math.abs(size / 2) }),
      ...(!next && { left: -Math.abs(size / 2) }),
    }}
  >
    <IconButton
      aria-label={`${next ? 'Next' : 'Previous'} slide`}
      onClick={onClick}
    >
      <ChevronDownIcon
        className={cn('transform', {
          '-rotate-90': next,
          'rotate-90': !next,
        })}
      />
    </IconButton>
  </div>
);
