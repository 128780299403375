import {
  DealCatalogSort,
  getCatalogueById,
  listCatalogueDealsById,
} from '~/domain/catalogue';
import { defaultOnError } from '~/domain/error';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useParams, useSearchParams } from '@remix-run/react';

const PER_PAGE = 20;

export const useFetchCatalogueDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sort = Object.values<string>(DealCatalogSort).includes(
    searchParams.get('sort') || ''
  )
    ? (searchParams.get('sort') as DealCatalogSort)
    : DealCatalogSort.DEFAULT;

  const setSort = (value: DealCatalogSort) => {
    setSearchParams({ sort: value }, { replace: true });
  };

  const { catalogueId } = useParams();

  const { data, ...rest } = useInfiniteQuery(
    ['listBatchOutlets', { sort, catalogueId }],
    ({ pageParam: pageToken = '1' }) =>
      listCatalogueDealsById({
        perPage: PER_PAGE,
        pageToken,
        sort,
        catalogueId: catalogueId as string,
      }),
    {
      onError: defaultOnError,
      getNextPageParam: (last) => last.nextPageToken,
    }
  );

  const pages = data?.pages;

  const { data: catalogueDetails } = useQuery(
    ['getCatalogueById', catalogueId],
    () => getCatalogueById(catalogueId as string),
    { onError: defaultOnError }
  );

  return {
    catalogueDetails,
    sort,
    setSort,
    pages,
    ...rest,
  };
};
