import cn from 'classnames';
import { Loader, Button } from '@setel/web-ui';
import { useTranslation } from 'react-i18next';

type LoadMoreProps = {
  loading: boolean;
  show: boolean;
  onFetchMore(): void;
  className?: string;
};

export const LoadMore = ({
  loading,
  show,
  onFetchMore,
  className,
}: LoadMoreProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn('flex justify-center', className)}>
      {loading && <Loader type="spinner-blue" />}
      {show && !loading && (
        <Button
          variant="outline"
          onClick={() => onFetchMore()}
          className="w-full md:w-auto mx-5 md:mx-0"
        >
          {t('buttons.loadMore')}
        </Button>
      )}
    </div>
  );
};
