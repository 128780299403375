import { BareButton, ChevronRightIcon } from '@setel/web-ui';
import { DealCatalogWithDeals } from '~/domain/catalogue';
import { RootNavigationRoute } from '~/features/Navigation';
import { routeTo } from '~/shared';
import { Deal } from '../../Deal';
import { Arrow } from './Arrow';
import { EVENT_SOURCES } from '~/shared/analytics.events';
import { Link, useNavigate } from '@remix-run/react';
import { useScrollSnapSlider } from '~/components/ScrollSnapSlider';
import { useTranslation } from 'react-i18next';

const DEALS_PER_CATALOUGE = 7;

type CataloguePreviewProps = DealCatalogWithDeals & {
  className?: string;
};

export const CataloguePreview = ({
  _id: catalogueId,
  title,
  deals,
  className,
  totalDeals,
}: CataloguePreviewProps) => {
  const navigate = useNavigate();

  const {
    sliderProps,
    getSlideItemProps,
    canNext,
    canPrev,
    goToNextSlide: next,
    goToPrevSlide: prev,
  } = useScrollSnapSlider({
    containerMode: true,
    slideBaseWidth: 200,
    slideSpacing: 16,
  });
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="mb-2 flex justify-between pr-6 md:pr-0 items-center">
        <p className="text-gray-dark font-medium text-lg">{title}</p>
        {totalDeals >= DEALS_PER_CATALOUGE && (
          <Link
            to={{
              pathname: routeTo(RootNavigationRoute.CATALOGUE, {
                catalogueName: encodeURIComponent(title),
                catalogueId,
              }),
              search: new URLSearchParams({
                source: EVENT_SOURCES.deal_main_page_catalogue_view_more,
              }).toString(),
            }}
          >
            <BareButton rightIcon={<ChevronRightIcon />}>
              {t('buttons.viewMore')}
            </BareButton>
          </Link>
        )}
      </div>

      <div className="-mx-5 sm:mx-0 relative">
        <div className="hidden sm:block">
          {canPrev && <Arrow onClick={prev} />}
          {canNext && <Arrow next onClick={next} />}
        </div>

        <div className="rewards-catalogue-slider" {...sliderProps}>
          {deals.map((deal, i) => (
            <div
              key={deal._id}
              className="p-1 cursor-pointer"
              role="link"
              tabIndex={0}
              onClick={() => {
                navigate({
                  pathname: routeTo(RootNavigationRoute.DEAL, {
                    dealId: deal._id,
                    dealName: encodeURIComponent(
                      deal.voucherBatch.content.title ?? '-'
                    ),
                  }),
                  search: new URLSearchParams({
                    source: EVENT_SOURCES.deal_main_page,
                  }).toString(),
                });
              }}
              {...getSlideItemProps(i)}
            >
              <Deal {...deal} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
