import { Select, SelectOptionProps } from '~/components/Form';
import { DealCatalogSort } from '~/domain/catalogue';
import { FC } from 'react';
import * as React from 'react';

export type SortProps = {
  sort: DealCatalogSort;
  onSort(sort: DealCatalogSort): void;
};

const options: SelectOptionProps[] = [
  { label: 'Recommended', value: DealCatalogSort.DEFAULT },
  {
    label: 'Points: Low to high',
    value: DealCatalogSort.POINT_LOW_TO_HIGH,
  },
  {
    label: 'Points: High to low',
    value: DealCatalogSort.POINT_HIGH_TO_LOW,
  },
];

export const Sort: FC<React.PropsWithChildren<SortProps>> = ({
  sort,
  onSort,
}) => {
  return (
    <div className="flex items-center w-full md:w-64 mt-4 md:mt-0">
      <span className="text-gray-dark text-lg mr-4 hidden md:block">Sort</span>
      <Select options={options} onChange={onSort} value={sort} />
    </div>
  );
};
